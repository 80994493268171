
































import Vue, {PropType} from "vue";
import {QuestionaryQuestionData, VenueQuestionaryData, VenueQuestionaryQuestionData} from "@/types";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  name: "questionary-question-table-item" as string,
  props: {
    data: Object as PropType<QuestionaryQuestionData>,
    venueCampaigns: Array as PropType<Array<VenueQuestionaryData>>
  },

  data() {
    return {
      showQuestion: false,
      i18: i18n
    }
  },

  computed: {
    filteredCampaignsContainingQuestions(): Array<any> {
      const filteredCampaigns: Array<any> = [] as Array<any>;

      this.venueCampaigns.forEach((c: VenueQuestionaryData) => {
        c.questions.forEach((i: VenueQuestionaryQuestionData) => {
          if (i.id === this.data.id) {
            filteredCampaigns.push(c)
          }
        })
      })

      return filteredCampaigns;
    }
  }
})
