

















































































import Vue, {PropType} from "vue";
import {VenueQuestionaryData} from "@/types";
import QuestionaryQuestionTableItem from "@/components/questionary/QuestionaryQuestionTableItem.vue";
import SelectVenuesModal from "@/components/modals/SelectVenuesModal.vue";
import AddQuestionaryModal from "@/components/modals/AddQuestionaryModal.vue";
import DeleteItemConfirmModal from "@/components/modals/DeleteItemConfirmModal.vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment";

export default Vue.extend({
   name: "venue-questionary-table-item" as string,

  components: {
    "questionary-question-table-item": QuestionaryQuestionTableItem,
    "select-venues-modal": SelectVenuesModal,
    "add-questionary-modal": AddQuestionaryModal,
    "delete-item-confirm-modal": DeleteItemConfirmModal
  },

  props: {
    data: Object as PropType<VenueQuestionaryData>,
    activeVenueQuestionaries: Array as PropType <Array<VenueQuestionaryData>>,
    venueQuestionaries: Array as PropType<Array<VenueQuestionaryData>>
  },

  data() {
    return {
      showQuestion: false
    }
  },

  computed: {
    ...mapGetters("venueQuestionaryModule", {
      venueQuestionary: "GET_VENUE_QUESTIONARY",
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
    }),
    selectedSurveyVenues(): Array<number> {
      return this.data.venues.map(v => v.id);
    },
    selectedSurveyQuestions() {
      let notDeletedQuestions = [];
      this.data.questions.forEach((q)=>{
        if(q.deleted_at == 0){
          notDeletedQuestions.push(q)
        }
      })
      return notDeletedQuestions;
    },
    isCampaignActive(){
      let nowTimestamp = moment().unix()
      let startTimestamp = moment(this.data.start_at + " " + "00:00:00").unix();
      let endTimestamp = moment(this.data.end_at + " " + "23:59:59").unix();
      if (nowTimestamp >= startTimestamp && nowTimestamp <= endTimestamp && this.data.active){
        return true
      }else {
        return false
      }
    },
    originVenueQuestionary(){
      if(!this.venueQuestionaries) return;
      return this.venueQuestionaries.find((q) => q.id == this.data.id)
    },
    isDeleteEnabled(){
      if(!this.data.venues.length || !this.venueQuestionaries) return;
      const venueQuestionary = this.venueQuestionaries.find((q) => q.id == this.data.id)
      return venueQuestionary.venues.length === this.data.venues.length
    }
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      deleteQuestionaryQuestion: "DELETE_QUESTIONARY_QUESTION",
      deleteVenueQuestionary: "DELETE_VENUE_QUESTIONARY"
    }),
    showQuestions(): void {
      this.showQuestion = !this.showQuestion
    },
    async deleteQuestion(id: number): Promise<void> {
      await this.deleteQuestionaryQuestion(id);
    },
    questionaryPeriod(data: VenueQuestionaryData): string {
      if(data.main) return;
      return `${data.start_at.split('-').reverse().join('.')} - ${data.end_at.split('-').reverse().join('.')}`;
    },
    async removeCampaign(id: number): Promise<void> {
      await this.deleteVenueQuestionary(id);
    }
  }
})
