




























import Vue, {PropType} from "vue";

export default Vue.extend({
  name: "venue-question-limit-modal" as string,

  props: {
    id: Number as PropType<number>
  }

})
