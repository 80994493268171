

























































































































import Vue, {PropType} from "vue";
import SelectVenuesModal from "@/components/modals/SelectVenuesModal.vue";
import VenueQuestionLimitModal from "@/components/modals/VenueQuestionLimitModal.vue";
import VenueQuestionaryConfirmModal from "@/components/modals/VenueQuestionaryConfirmModal.vue";
import ValidationWarningModal from "@/components/modals/ValidationWarningModal.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import { mapActions, mapGetters } from "vuex";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";
import {
  SubscriptionProductsData,
  VenueData,
  VenueQuestionaryData,
  VenueQuestionaryQuestionData,
  VenueQuestionaryVenueData,
  VenueSubscriptionData
} from "@/types";
import {ToggleButton} from "vue-js-toggle-button";

export default Vue.extend({
  mixins: [DatePicker],
  name: "add-questionary-modal" as string,

  props: {
    id: Number as PropType<number>,
    title: String as PropType<string>,
    isEdit: Boolean as PropType<boolean>,
    questionaryId: Number as PropType<number>,
    selectedCampaignName: String as PropType<string>,
    selectedCampaignDurationFrom: String as PropType<string>,
    selectedCampaignDurationTo: String as PropType<string>,
    selectedIsActive: Boolean as PropType<boolean>,
    selectedCampaignVenues: Array as PropType<Array<VenueQuestionaryVenueData>>,
    selectedCampaingQuestions: Array as PropType<Array<VenueQuestionaryQuestionData>>,
    venueCampaigns: Array as PropType<Array<VenueQuestionaryData>>,
    venueSubscriptionData: Array as PropType<Array<VenueSubscriptionData>>,
    venueSubscriptionProductsData: Array as PropType<Array<SubscriptionProductsData>>
  },

  components: {
    "select-venues-modal": SelectVenuesModal,
    "venue-question-limit-modal": VenueQuestionLimitModal,
    "venue-questionary-confirm-modal": VenueQuestionaryConfirmModal,
    "validation-warning-modal": ValidationWarningModal,
    "custom-select": CustomSelect,
    ToggleButton
  },

  data() {
    return {
      campaignName: this.isEdit ? this.selectedCampaignName : "",
      campaignDuration: this.isEdit ? [this.selectedCampaignDurationFrom,this.selectedCampaignDurationTo] : [] as Array<string>,
      isActive: this.isEdit ? this.selectedIsActive : true,
      selectedQuestions:  [] as Array<number>,
      selectedVenues: [] as Array<number>,
      allowedVenues: [] as Array<VenueData>,
      randomId: Math.floor(Math.random() * 100000),
      validationMessage: '',
      lang: DatePicker,
      i18: i18n,
      infoDisabled: "Please note that this venue has already active venue survey for date selected. If you want to add this venue into current survey, please deselect it from previous survey or adjust dates of active surveys to avoid their overlapping.",
      infoBasic: "This venue has BASIS subscription package not covering creating tailored questions for customer questionnaire.",
      resetSelected: false
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if("newQuestionnairePopUp" + this.id === modalId){
        this.selectedQuestions = this.isEdit ?  this.selectedQuestions : [] as Array<number>;
        this.selectedVenues = this.isEdit ?  this.selectedVenues : [] as Array<number>;
        this.campaignName = this.isEdit ? this.selectedCampaignName : "";
        this.campaignDuration = this.isEdit ? [this.selectedCampaignDurationFrom,this.selectedCampaignDurationTo] : [] as Array<string>;
        this.isActive = this.isEdit ? this.isActive : true;
      }
    })
    this.setQuestionaryAvailableVenues({dateFrom: this.campaignDuration[0], dateTo: this.campaignDuration[1]});
  },
  computed: {
    ...mapGetters("venueQuestionaryModule", {
      questionaryQuestions: "GET_QUESTIONARY_QUESTIONS",
      questionaryAvailableVenues: "GET_QUESTIONARY_AVAILABLE_VENUES",
    }),
    ...mapGetters("venueModule", {
      venues: "GET_VENUES"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    filteredCampaignsVenues(): Array<number> {
      let filteredVenues: Array<any> = [] as Array<any>;
      this.questionaryAvailableVenues.forEach((v: any) => {
        if(v.campaign_active){
          filteredVenues.push(v)
        }
      })

      if (this.isEdit) {
        const filteredVenuesWithSelected = [];

        filteredVenues.forEach((v: any) => {
          if (!this.selectedCampaignVenues.find(i => i.id === v.id)) {
            filteredVenuesWithSelected.push(v)
          }
        })
        filteredVenues = filteredVenuesWithSelected;
      }

      return filteredVenues.map(v=> v.id);
    },
    selectedSurveyQuestions(): Array<number> {
      if (!this.selectedCampaignVenues) return [];
      return this.selectedCampaingQuestions.map(q => q.id);
    },
    selectedSurveyVenues(): Array<number> {
      if (!this.selectedCampaignVenues) return [];
      return this.selectedCampaignVenues.map(v => v.id);
    },
    filterPremiumVenues(): Array<VenueData> {
      const premiumVenues: Array<VenueData> = [] as Array<VenueData>;

      if (!this.venueSubscriptionData.length || !this.venueSubscriptionProductsData.length) return;

      this.venues.forEach((v: VenueData) => {
        const venueSubscription: VenueSubscriptionData = this.venueSubscriptionData.find(s => s.venueId === v.id);
        if(venueSubscription){
          const venueSubscriptionProduct: SubscriptionProductsData = this.venueSubscriptionProductsData.find(p => p.id === venueSubscription.stripeProductWithPriceId);

          if (venueSubscriptionProduct.songorooPackageType === 2) premiumVenues.push(v);
        }
      })

      return premiumVenues
    },
    areFieldsValid(): boolean {
      return !!this.campaignName;
    },
    filterDeactivatedVenues(){
      const deactivatedVenues: Array<number> = [] as Array<number>;

      if (!this.venueSubscriptionData.length || !this.venueSubscriptionProductsData.length) return;
      let nowTimestamp = Math.floor(Date.now() / 1000);
      this.venues.forEach((v: VenueData) => {
        const venueSubscription: VenueSubscriptionData = this.venueSubscriptionData.find(s => s.venueId === v.id);
        if(venueSubscription){
          if (Number(venueSubscription.subscriptionEndsAt) <  nowTimestamp || venueSubscription.subscriptionStatus == 'canceled') deactivatedVenues.push(v.id);
        }
      })
      return deactivatedVenues
    },
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      setQuestionaryAvailableVenues: "FETCH_AVAILABLE_VENUE_QUESTIONARY",
      addVenueQuestionary: "ADD_VENUE_QUESTIONARY",
      editVenueQuestionary: "EDIT_VENUE_QUESTIONARY",
    }),
    setSelectedQuestions(value: Array<number>): void {
      this.selectedQuestions = value;
    },
    setSelectedVenues(value: Array<number>): void {
      this.selectedVenues = value;
    },
    setAllowedVenues(value: Array<VenueData>): void {
      this.allowedVenues = value;
    },
    async confirmModal(): Promise<void> {
      if ((!this.areFieldsValid || !this.campaignDuration.length) && this.selectedVenues.length) {
        this.validationMessage = this.$t('All fields are required.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      if (this.allowedVenues.length == 0){
        this.validationMessage = this.$t('No venues available for this survey.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      if (!this.selectedVenues.length) {
        this.validationMessage = this.$t('Please select venues.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      if (!this.selectedQuestions.length) {
        this.validationMessage = this.$t('Please select at least one question.');
        this.$bvModal.show("validation-warning-modal" + this.randomId);
        return;
      }

      if (this.selectedQuestions.length > 5) {
        this.$bvModal.show("limit-question-modal" + this.randomId);
      } else if (this.selectedQuestions.length === 5) {
          const payload: object = {
            name: this.campaignName,
            start_at: this.campaignDuration[0],
            end_at: this.campaignDuration[1],
            active: this.isActive,
            venue_owner_id: this.companyId,
            question_ids: this.selectedQuestions,
            venue_ids: this.selectedVenues
          }

          if (this.isEdit) {
            await this.editVenueQuestionary({payload: payload, id: this.questionaryId});
          } else {
            await this.addVenueQuestionary(payload);
            this.resetSelected = true;
            this.isActive = this.isEdit ? this.isActive : true;
          }
          this.$bvModal.hide("newQuestionnairePopUp" + this.id);
      } else if (this.selectedQuestions.length < 5) {
        this.$bvModal.show("confirm-questionary-modal" + this.randomId);
      }
    }
  },
  watch: {
    selectedSurveyVenues: {
      immediate: true,
      handler(value): void {
        if (value) {
          this.selectedVenues = value
        }
      }
    },
    selectedSurveyQuestions: {
      immediate: true,
      handler(value): void {
        if (value) {
          this.selectedQuestions = value
        }
      }
    },
    campaignDuration: {
      async handler() {
        await this.setQuestionaryAvailableVenues({dateFrom: this.campaignDuration[0], dateTo: this.campaignDuration[1]});
      }
    }

  }
})
