

































































import Vue from "vue";
import SearchBar from "@/components/search-bar.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import CloseCircleIcon from "@iconify-icons/ion/close-circle-outline";
import {mapActions, mapGetters} from "vuex";
import {SubscriptionProductsData, VenueData, VenueSubscriptionData} from "@/types";
import DatePicker from "@/mixins/DatePicker";
import i18n from "@/i18n/i18n";

export default Vue.extend({
  mixins: [DatePicker],
  name: "venue-questionary-header",

  components: {
    "search-bar": SearchBar,
    "custom-select": CustomSelect,
  },

  data() {
    return {
      icons: {
        cancel: CloseCircleIcon,
      },
      activeCampaigns: false,
      selectedVenues: [] as Array<number>,
      startAt: "",
      endAt: "",
      filterPayloadData: {},
      searchKey: "",
      lang: DatePicker,
      i18: i18n
    }
  },

  computed: {
    ...mapGetters("venueModule", {
      venues: "GET_VENUES"
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    disableFilter(): boolean {
      return !this.selectedVenues.length;
    },
    filterPremiumVenues(): Array<VenueData> {
      const premiumVenues: Array<VenueData> = [] as Array<VenueData>;

      if (!this.venuesSubscription.length || !this.subscriptionProducts.length) return;

      this.venues.forEach((v: VenueData) => {
        const venueSubscription: VenueSubscriptionData = this.venuesSubscription.find(s => s.venueId === v.id);
        if(venueSubscription){
          const venueSubscriptionProduct: SubscriptionProductsData = this.subscriptionProducts.find(p => p.id === venueSubscription.stripeProductWithPriceId);

          if (venueSubscriptionProduct.songorooPackageType === 2) premiumVenues.push(v);
        }

      })

      return premiumVenues;
    }
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      filterActiveCampaigns: "FILTER_ACTIVE_CAMPAIGNS",
      setVenueQuestionary: "FETCH_VENUE_QUESTIONARY",
      setQuestionaryQuestions: "FETCH_QUESTIONARY_QUESTIONS"
    }),
    filterByActiveCampaigns(): void {
      this.$emit("resetPagination");
      this.filterActiveCampaigns(this.activeCampaigns)
    },
    async filterCampaigns(): Promise<void> {
      let uniqueVenuesIdsString: string = "";
      this.selectedVenues.forEach((v: number) => { uniqueVenuesIdsString += `venue_ids=${v}&` })

      const payload: object = {
        venue_ids: uniqueVenuesIdsString.slice(0, -1),
      }

      if (this.startAt) payload["start_at"] = this.startAt;
      if (this.endAt) payload["end_at"] = this.endAt;

      this.filterPayloadData = payload;

      await this.setVenueQuestionary({payload, name: this.searchKey});
    },
    resetPagination(): void {
      this.$emit("resetPagination");
    },
    setVenues(value: Array<number>): void {
      this.selectedVenues = value;
    },
    setSearch(value: string): void {
      this.searchKey = value;
    }
  },

  watch: {
    companyId: {
      handler(newValue: number, oldValue: number): void {
        if (newValue != oldValue) {
          this.selectedVenues = [];
        }
      }
    }
  }
})
