
























































































import Vue from "vue";
import Layout from "@/router/layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import VenueQuestionaryHeader from "@/components/questionary/VenueQuestionaryHeader.vue";
import VenueQuestionaryTableItem from "@/components/questionary/VenueQuestionaryTableItem.vue";
import AddQuestionaryModal from "@/components/modals/AddQuestionaryModal.vue";
import PlusIcon from "@iconify-icons/ion/add-outline";
import {mapActions, mapGetters} from "vuex";
import {VenueQuestionaryData} from "@/types";
import moment from "moment";

export default Vue.extend({
  name: "venue-survey",

  components: {
    Layout,
    PageHeader,
    "venue-questionary-table-item": VenueQuestionaryTableItem,
    "venue-questionary-header": VenueQuestionaryHeader,
    "add-questionary-modal": AddQuestionaryModal
  },

  data() {
    return {
      title: "Venue survey",
      icons: {
        add: PlusIcon,
      },
      newQuestionnaireModal: false,
      selected: "radio1",
      currentPage: 1,
      perPage: 10,
      items: [],
      randomId: Math.floor(Math.random() * 100000)
    }
  },

  async mounted(): Promise<void> {
    await this.setVenueQuestionary({});
    await this.setPermissionAvailableQuestionaries([...this.venueQuestionary])
    await this.setQuestionaryQuestions();
    await this.setVenuesSubscription();
    await this.setSubscriptionProducts();
  },

  computed: {
    ...mapGetters("venueQuestionaryModule", {
      venueQuestionary: "GET_VENUE_QUESTIONARY",
      questionaryFilter: "GET_ACTIVE_FILTER",
      permissionAvailableVenueQuestionary: "GET_PERMISSION_AVAILABLE_QUESTIONARIES"
    }),
    ...mapGetters("venueSubscriptionModule", {
      venuesSubscription: "GET_VENUES_SUBSCRIPTION",
      subscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS"
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    }),
    totalVenueQuestionary(): number {
      return this.permissionAvailableVenueQuestionary.length;
    },
    sortedSurveys(){
      if(!this.permissionAvailableVenueQuestionary.length) return "";
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      let sorted = this.permissionAvailableVenueQuestionary.sort((q1, q2) => {return ((q2.main === q1.main)? 0 : q2? -1 : 1) ||  q2.id - q1.id} )
      return sorted
    },
    venueQuestionaryList(): Array<VenueQuestionaryData> {
      return this.displayedQuestionaries.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
    },
    displayedQuestionaries(): Array<VenueQuestionaryData>{
      return !this.questionaryFilter ? this.sortedSurveys : this.activeVenueQuestionaries
    },
    activeVenueQuestionaries(): Array<VenueQuestionaryData> {
      let activeVenueQuestionaries = [];
      let nowTimestamp = moment().unix()
      this.permissionAvailableVenueQuestionary.forEach((v: VenueQuestionaryData) => {
        if(v.main) return;
        const startTimestamp = moment(v.start_at + " " + "00:00:00").unix();
        const endTimestamp = moment(v.end_at + " " + "23:59:59").unix();
        if(nowTimestamp >= startTimestamp && nowTimestamp <= endTimestamp && v.active){
          activeVenueQuestionaries.push(v)
        }
      })
      if(!activeVenueQuestionaries.length){
        activeVenueQuestionaries = this.permissionAvailableVenueQuestionary.filter((x) => x.main == true)
      }
      return activeVenueQuestionaries
    },
  },

  methods: {
    ...mapActions("companyModule", {
      setCompany: "SET_COMPANY",
    }),
    ...mapActions("venueQuestionaryModule", {
      setVenueQuestionary: "FETCH_VENUE_QUESTIONARY",
      setQuestionaryQuestions: "FETCH_QUESTIONARY_QUESTIONS",
      setPermissionAvailableQuestionaries: "FETCH_PERMISSION_AVAILABLE_QUESTIONARIES"
    }),
    ...mapActions("venueSubscriptionModule", {
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS"
    }),
    ...mapActions("venueModule", {
      setVenues: "FETCH_VENUES"
    }),
    goToListOfQuestions() {
      this.$router.push("/venue-survey/list-of-questions");
    },
    resetPagination(): void {
      this.currentPage = 1;
    }
  },

  watch: {
    companyId: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setVenues({venueRatings: false, name: ''});
          await this.setVenueQuestionary({});
          await this.setQuestionaryQuestions();
          await this.setVenuesSubscription();
        }
      }
    },
    venueQuestionary: {
      async handler(newValue: number, oldValue: number): Promise<void> {
        if (newValue != oldValue) {
          await this.setPermissionAvailableQuestionaries([...this.venueQuestionary])
        }
      }
    }
  }
})
