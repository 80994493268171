









































import Vue, {PropType} from "vue";
import {mapActions, mapGetters} from "vuex";

export default Vue.extend({
  name: "venue-questionary-confirm-modal" as string,

  props: {
    id: Number as PropType<number>,
    isEdit: Boolean as PropType<boolean>,
    questionaryId: Number as PropType<number>,
    addModalId: Number as PropType<number>,
    isActive: Boolean as PropType<boolean>,
    campaignName: String as PropType<string>,
    campaignDurationFrom: String as PropType<string>,
    campaignDurationTo: String as PropType<string>,
    selectedQuestions: Array as PropType<Array<number>>,
    selectedVenues: Array as PropType<Array<number>>
  },

  computed: {
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID"
    })
  },

  methods: {
    ...mapActions("venueQuestionaryModule", {
      addVenueQuestionary: "ADD_VENUE_QUESTIONARY",
      editVenueQuestionary: "EDIT_VENUE_QUESTIONARY",
    }),
    async addNewVenueQuestionary(): Promise<void> {
      const payload: object = {
        name: this.campaignName,
        start_at: this.campaignDurationFrom,
        end_at: this.campaignDurationTo,
        active: this.isActive,
        venue_owner_id: this.companyId,
        question_ids: this.selectedQuestions,
        venue_ids: this.selectedVenues
      }

      if (this.isEdit) {
        await this.editVenueQuestionary({payload: payload, id: this.questionaryId});
      } else {
        await this.addVenueQuestionary(payload);
        this.$emit('resetSelected');
      }
      this.$bvModal.hide("confirm-questionary-modal" + this.id);
      this.$bvModal.hide("newQuestionnairePopUp" + this.addModalId);
    }
  }
})
